// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---plugin-templates-landing-page-index-js": () => import("/opt/build/repo/plugin/Templates/LandingPage/index.js" /* webpackChunkName: "component---plugin-templates-landing-page-index-js" */),
  "component---src-templates-product-listing-page-index-js": () => import("/opt/build/repo/src/Templates/ProductListingPage/index.js" /* webpackChunkName: "component---src-templates-product-listing-page-index-js" */),
  "component---plugin-templates-product-detail-page-index-js": () => import("/opt/build/repo/plugin/Templates/ProductDetailPage/index.js" /* webpackChunkName: "component---plugin-templates-product-detail-page-index-js" */),
  "component---src-templates-category-page-index-js": () => import("/opt/build/repo/src/Templates/CategoryPage/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-templates-order-detail-page-index-js": () => import("/opt/build/repo/src/Templates/OrderDetailPage/index.js" /* webpackChunkName: "component---src-templates-order-detail-page-index-js" */),
  "component---plugin-templates-profile-page-index-js": () => import("/opt/build/repo/plugin/Templates/ProfilePage/index.js" /* webpackChunkName: "component---plugin-templates-profile-page-index-js" */),
  "component---src-templates-cart-page-index-js": () => import("/opt/build/repo/src/Templates/CartPage/index.js" /* webpackChunkName: "component---src-templates-cart-page-index-js" */),
  "component---plugin-templates-video-category-page-js": () => import("/opt/build/repo/plugin/Templates/VideoCategoryPage.js" /* webpackChunkName: "component---plugin-templates-video-category-page-js" */),
  "component---plugin-templates-hot-topic-page-js": () => import("/opt/build/repo/plugin/Templates/HotTopicPage.js" /* webpackChunkName: "component---plugin-templates-hot-topic-page-js" */),
  "component---plugin-templates-ivf-physician-page-js": () => import("/opt/build/repo/plugin/Templates/IVFPhysicianPage.js" /* webpackChunkName: "component---plugin-templates-ivf-physician-page-js" */),
  "component---plugin-templates-embryologist-page-js": () => import("/opt/build/repo/plugin/Templates/EmbryologistPage.js" /* webpackChunkName: "component---plugin-templates-embryologist-page-js" */),
  "component---src-templates-select-cvs-page-index-js": () => import("/opt/build/repo/src/Templates/SelectCvsPage/index.js" /* webpackChunkName: "component---src-templates-select-cvs-page-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

